import firebase from 'gatsby-plugin-firebase';
import React, { FC, useEffect, useState } from 'react';

export interface AuthInfo {
  ready: boolean;
  authError: Error | null;
  user: firebase.User | null;
  signOut: () => void;
}

export const AuthContext = React.createContext<AuthInfo>({
  ready: false,
  authError: null,
  user: null,
  signOut: () => {
    return;
  },
});

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [authError, setAuthError] = useState<Error | null>(null);
  const [user, setUser] = useState<firebase.User | null>(null);

  const signOut = () => {
    setAuthError(null);
    setUser(null);
    firebase.auth().signOut();
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      function (user) {
        if (user) {
          setUser(user);
        } else {
          signOut();
        }
        setReady(true);
      },
      function (error: firebase.auth.Error) {
        // TODO: Set and handle authError.
        console.log('Encountered an error signing in:', error);
      }
    );

    return unregisterAuthObserver; // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return <AuthContext.Provider value={{ ready, authError, user, signOut }}>{children}</AuthContext.Provider>;
};
