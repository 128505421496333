import React, { FC, useEffect, useState } from 'react';

interface PaddleInfo {
  total: number;
  currency: string;
  quantity: number;
}

function onFullyLoaded(callback: () => void) {
  if (document.readyState === 'complete') {
    // Document is already fully loaded
    callback();
  } else {
    // Wait for the document to be fully loaded
    window.addEventListener('load', callback);
  }
}

export const PaddleContext = React.createContext<PaddleInfo | null>(null);

export const PaddleProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [paddleInfo, setPaddleInfo] = useState<PaddleInfo | null>(null);

  useEffect(() => {
    onFullyLoaded(() => {
      (window as any).Paddle.Setup({
        vendor: 123417,
        eventCallback: (data) => {
          setPaddleInfo({
            total: data.eventData.checkout.prices.customer.total,
            currency: data.eventData.checkout.prices.customer.currency,
            quantity: data.eventData.product.quantity,
          });
        },
      });
    });
  }, []);

  return <PaddleContext.Provider value={paddleInfo}>{children}</PaddleContext.Provider>;
};
