// src/theme.ts
import { Button, createTheme, MantineColorsTuple } from '@mantine/core';

const promlabsOrange: MantineColorsTuple = [
  '#ffefe5',
  '#ffded0',
  '#f8bca2',
  '#f39670',
  '#ef7746',
  '#ec632b',
  '#ec581c',
  '#d3480f',
  '#bc3f0a',
  '#a43303',
];

export const theme = createTheme({
  colors: {
    promlabsOrange,
  },
  primaryColor: 'promlabsOrange',
  fontFamily: 'Lato',
  // components: {
  //   Button: Button.extend({
  //     styles: {
  //       root: {
  //         transition: 'all 0.2s ease-in-out',
  //       },
  //     },
  //   }),
  // },
  headings: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    sizes: {
      h1: {
        fontSize: '40px',
      },
      h2: {
        fontSize: '28px',
      },
      h3: {
        fontSize: '22px',
      },
      h4: {
        fontSize: '18px',
      },
      h5: {
        fontSize: '16px',
      },
      h6: {
        fontSize: '14px',
      },
    },
  },
});
