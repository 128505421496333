/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { theme } from './src/theme';

import '@fontsource/lato/latin.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';

import React from 'react';
import { AuthProvider } from './src/context/AuthContext';
import { UserDataProvider } from './src/context/UserDataContext';
import { SiteSettingsProvider } from './src/context/SiteSettingsContext';
import { refLocalStorageKey } from './src/constants';
import SigninModal from './src/components/signin-modal';
import { PaddleProvider } from './src/context/PaddleContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

if (document.referrer !== '' && !window.localStorage.getItem(refLocalStorageKey)) {
  window.localStorage.setItem(refLocalStorageKey, document.referrer);
}

// require('prismjs/themes/prism.css');
require('prismjs/themes/prism-tomorrow.css');

export const wrapPageElement = ({ element }) => {
  return (
    <PaddleProvider>
      <MantineProvider theme={theme}>
        <ModalsProvider modals={{ signin: SigninModal }}>{element}</ModalsProvider>
      </MantineProvider>
    </PaddleProvider>
  );
};

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <SiteSettingsProvider>
      <AuthProvider>
        <UserDataProvider>{element}</UserDataProvider>
      </AuthProvider>
    </SiteSettingsProvider>
  </QueryClientProvider>
);
