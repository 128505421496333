import React, { FC, useContext, useEffect, useState } from 'react';
import { useAPIQuery } from '../hooks/api';
import { AuthContext, AuthInfo } from './AuthContext';

interface UserData {
  // List of Paddle product IDs.
  ready: boolean;
  trainings: number[];
  addTraining: (training: number) => void;
  loadTrainingsError: Error | null;
  auth: AuthInfo;
}

export const UserDataContext = React.createContext<UserData>({
  ready: false,
  trainings: [],
  addTraining: (training: number) => {
    return;
  },
  loadTrainingsError: null,
  auth: {
    ready: false,
    authError: null,
    user: null,
    signOut: () => {
      return;
    },
  },
});

export const UserDataProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [clientSideTrainings, setClientSideTrainings] = useState<number[]>([]);

  const auth = useContext(AuthContext);

  const {
    data: trainings,
    error: loadTrainingsError,
    isPending,
    isFetching,
  } = useAPIQuery<number[]>({
    key: ['trainings', auth.user?.uid],
    path: '/trainings',
    enabled: auth.user !== null,
  });

  useEffect(() => {
    setClientSideTrainings([]);
  }, [auth.user?.uid]);

  if (auth.user === null) {
    return (
      <UserDataContext.Provider
        value={{
          ready: auth.ready,
          trainings: [],
          addTraining: (training: number) => {
            return;
          },
          loadTrainingsError: null,
          auth,
        }}
      >
        {children}
      </UserDataContext.Provider>
    );
  }

  return (
    <UserDataContext.Provider
      value={{
        ready: !isPending && !isFetching,
        trainings: trainings !== undefined ? [...trainings, ...clientSideTrainings] : clientSideTrainings,
        addTraining: (training: number) => {
          setClientSideTrainings((prev) => [...prev, training]);
        },
        loadTrainingsError,
        auth,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
