import React, { FC } from 'react';
import { useAPIMutation, useAPIQuery } from '../hooks/api';

interface SiteSettings {
  ready: boolean;
  error: Error | null;
  siteDisabled: boolean;
  setSiteDisabled: (disabled: boolean) => void;
}

export const SiteSettingsContext = React.createContext<SiteSettings>({
  ready: false,
  error: null,
  siteDisabled: false,
  setSiteDisabled: () => {
    return;
  },
});

const siteSettingsQueryKey = ['site-settings'];

export const SiteSettingsProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const isAcendSite = process.env.GATSBY_PLATFORM === 'acend';

  const { data, error, isPending, isFetching } = useAPIQuery<{ siteDisabled: boolean }>({
    key: siteSettingsQueryKey,
    path: '/site-settings',
    enabled: isAcendSite,
  });

  const mutation = useAPIMutation<string, { siteDisabled: boolean }>({
    path: '/site-settings',
    invalidateKey: siteSettingsQueryKey,
  });

  if (!isAcendSite) {
    return (
      <SiteSettingsContext.Provider
        value={{
          ready: true,
          error: null,
          siteDisabled: false,
          setSiteDisabled: () => {
            return;
          },
        }}
      >
        {children}
      </SiteSettingsContext.Provider>
    );
  }

  return (
    <SiteSettingsContext.Provider
      value={{
        ready: !isPending && !isFetching,
        error,
        siteDisabled: data?.siteDisabled || false,
        setSiteDisabled: (disabled: boolean) => mutation.mutate({ siteDisabled: disabled }),
      }}
    >
      {children}
    </SiteSettingsContext.Provider>
  );
};
