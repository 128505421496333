import { QueryKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import firebase from 'firebase';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const API_URL = 'https://api.promlabs.com/api';

type SuccessAPIResponse<T> = {
  success: true;
  data: T;
};

type ErrorAPIResponse = {
  success: false;
  error?: string;
};

type APIResponse<T> = SuccessAPIResponse<T> | ErrorAPIResponse;

const createQueryFn =
  <T>({ path, user }: { path: string; user: firebase.User | null }) =>
  async ({ signal }: { signal: AbortSignal }) => {
    const userIDToken = await user?.getIdToken();

    const response = await fetch(`${API_URL}${path}`, {
      headers: userIDToken
        ? {
            Authorization: `Bearer ${userIDToken}`,
          }
        : {},
      signal,
    });

    if (!response.ok && !response.headers.get('content-type')?.startsWith('application/json')) {
      throw new Error(response.statusText);
    }

    const json: APIResponse<T> = await response.json();

    if (!json.success) {
      throw new Error(json.error || 'Missing error message in response');
    }

    return json.data as T;
  };

export const useAPIQuery = <T>({ key, path, enabled }: { key: QueryKey; path: string; enabled?: boolean }) => {
  const auth = useContext(AuthContext);

  return useQuery<T>({
    queryKey: key,
    queryFn: createQueryFn<T>({ path, user: auth.user }),

    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
};

export const useAPIMutation = <T, P>({ path, invalidateKey }: { path: string; invalidateKey?: QueryKey }) => {
  const auth = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation<T, Error, P>({
    mutationFn: async (data: P) => {
      const userIDToken = await auth.user?.getIdToken();

      const response = await fetch(`${API_URL}${path}`, {
        method: 'POST',
        headers: userIDToken
          ? {
              Authorization: `Bearer ${userIDToken}`,
            }
          : {},
        body: JSON.stringify(data),
      });

      if (!response.ok && !response.headers.get('content-type')?.startsWith('application/json')) {
        throw new Error(response.statusText);
      }

      const json: APIResponse<T> = await response.json();

      if (!json.success) {
        throw new Error(json.error || 'Missing error message in response');
      }

      return json.data as T;
    },
    onSuccess: () => {
      invalidateKey && queryClient.invalidateQueries({ queryKey: invalidateKey });
    },
  });
};
